.tag {
    .has-ellipsis {
        max-width: 10em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .delete, &.is-delete, &.has-delete-icon {
        @each $name, $pair in $colors {
            $color: nth($pair, 1);
            &.is-#{$name} {
                background: $color;
                &:hover {
                    background-color: darken($color, 10%);
                    text-decoration: none;
                }
            }
        }
    }
    &.has-delete-icon {
        padding: 0px;
        .icon:first-child:not(:last-child) {
            margin-right: 0px;
            margin-left: 0px;
        }
    }
}
