@import './variables';

// html {
//   @media screen and (min-width: 768px) {
//     font-size: 18px;
//   }
// }

// CSS Vars that can be overwritten by Location
:root {
  --header-title: initial;
  --header-subtitle: #333;
  --menu-category-title: crimson;
  --menu-item-title: initial;
  --sidebar-title: initial;
  --sidebar-label: crimson;
  --button-primary: crimson;
  --header-color: #0278ae;
}

body {
  font-family: sans-serif;
}

h1 {
  font-family: 'Lora', serif;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 10px 0;
  color: var(--header-color);
}

h2 {
  font-family: 'Lora', serif;
  font-weight: 400;
}

h3 {
  font-weight: bold;
  font-size: 1.1rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quote {
  font-family: 'Merriweather', serif;
  font-weight: 300;
  max-width: 372px;
  line-height: 1.3;
  font-size: 0.9rem;
  span.author {
    font-family: 'Lora', serif;
    font-weight: 400;
    font-style: italic;
    font-size: 0.8em;
    text-align: right;
    display: block;
    &::before {
      content: '- ';
    }
  }
  span.tags {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
    display: block;
    text-align: right;
    margin-bottom: 7px;
  }
}
